export default {
	resetInfo(state) {
    state.plans = []
    state.products = []
	},
	updateProducts(state, payload) {
		state.products = payload
  },
	updatePlans(state, payload) {
		state.coffee_plans = payload
  },
}

