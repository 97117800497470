
import api from '../../../api'
//import cookieHandler from '../../../../components/utils/cookies/cookieHandler'
import { checkResponse } from '../../error'
export default {
	async getCoffeePlans({ commit }, payload) {
		return api.shop.getCoffeePlans().then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok') commit('updatePlans', response.data)
			if (payload && payload.callback) payload.callback(result)
		})
	},
	async getAllProducts({ commit }, payload) {
		return api.shop.getAllProducts().then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok') commit('updateProducts', response.data)
			if (payload && payload.callback) payload.callback(result)
		})
	},
}
