import mutations from './shop-mutations'
import actions from './shop-actions'
//const ttp = process.env.TIME_TO_PULL ? process.env.TIME_TO_PULL : 1000

export const shop = {
	namespaced: true,
	state: {
    products: [],
    coffee_plans: [],
    timeOfLastUpdate: undefined,
    isUpdating: false
  },
	getters: {
    allShopProducts(state){
      return state.filter(product => product.visible)
    },
    coffee_plans(state){
      return state.coffee_plans
    },
    allAvailableCoffees(state) {
			return state.products.filter(
				(product) =>
					(product.category === 'Coffees' || product.category === 'Coffee Pods') &&
					product.visible
			)
		},
    getCoffeePods(_, getters) {
			return getters.allAvailableCoffees.filter((product) => product.product_type === 'pod')
		},
    getProductBySku: (state) => (sku) => {
			return state.products.find((product) => product.sku === sku)
		},
    getCoffeePlanBySku: (state) => (sku) => {
			return state.coffee_plans.find((plan) => plan.sku === sku)
		},
		getCoffeePlanById: (state) => (id) => {
			return state.coffee_plans.find((plan) => plan.id === id)
		},
    getCoffeesByPlanId: (state) => (id) => {
			return state.products.filter(
				(product) =>
					(product.category === 'Coffees' || product.category === 'Coffee Pods') &&
					product?.coffee?.coffee_plan_ids && product.coffee.coffee_plan_ids.includes(id)
			)
		},
	},
	mutations,
	actions,
}

