	export default {
		id: undefined,
		account_number: undefined,
		email: undefined,
		phone_nr: undefined,
		company_name: undefined,
		first_name: undefined,
		last_name: undefined,
		opt_in: false,
    	balance: 0.0,
		token: undefined,
		timeOfLastPull: undefined,
		pricePerKg: undefined,
		recurrables: [],
		receipts: [],
		addresses: [],
		cards: [],
    receiptPage: 0,
    receiptPageMax: 1
	}
