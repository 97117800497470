export const signup = {
	namespaced: true,
	state: {
		skip: false,
    toggle: false,
    progress: 20
	},
	getters: {
		progress_value: (state) => {
			return state.progress
		},
		skip_state: (state) => {
			return state.skip
		},
		skip_toggle: (state) => {
			return state.toggle
		},
	},
	mutations: {
		toggle_skip(state,newValue) {
			state.toggle = newValue
		},
		skip(state) {
			state.skip = true
		},
		reset_skip(state) {
			state.skip = false
		},
		set_progress(state, payload) {
			state.progress = payload
		},
	},
	actions: {
		skipToDetails({ commit }) {
      commit('skip')
		},
		resetSkipToDetails({ commit }) {
      commit('reset_skip')
		},
		skipON({ commit }) {
      commit('toggle_skip',true)
		},
		skipOFF({ commit }) {
      commit('toggle_skip',false)
		},
		setProgress({ commit }, payload) {
      commit('set_progress',payload)
		},
	},
}
