export const notifications = {
	namespaced: true,
	state: {
		notificationMessage: '',
		maxTime: 10,
		countdown: 0,
		type: '',
		lock: false,
	},
	getters: {
		notificationMessage: (state) => {
			return state.notificationMessage
		},
		maxTime: (state) => {
			return state.maxTime
		},
		countdown: (state) => {
			return state.countdown
		},
		type: (state) => {
			return state.type
		},
		lock: (state) => {
			return state.lock
		},
	},
	mutations: {
		setNotification(state, payload) {
			state.type = payload.type
			state.notificationMessage = payload.message
			state.countdown = payload.countdown
		},
		hideNotification(state) {
			state.countdown = 0
		},
		decreaseCountdown(state) {
			state.countdown = state.countdown > 0 ?  state.countdown - 1 : 0
		},
		setLock(state, lock) {
			state.lock = lock
		},
	},
	actions: {
		showNotification({ commit, getters }, payload) {
      const maxTime = payload.countdown ? payload.countdown : getters.maxTime
			commit('setNotification', {
				type: payload.type,
				message: payload.message,
				countdown: maxTime,
			})
			//start countdown
			for (let i = 1; i <= maxTime; i++) {
				setTimeout(function () {
					commit('decreaseCountdown')
				}, i * 1000)
			}
		},
	},
}
