import api from '../../../api'
import cookieHandler from '../../../../components/utils/cookies/cookieHandler'
import { checkResponse } from '../../error'
const DEFAULT_HOST_D2C = process.env.WEB_URL_D2C ? process.env.WEB_URL_D2C : 'http://127.0.0.1:8000'
export default {
	async login({ commit }, payload) {
		return api.users.login(payload.email, payload.password).then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok') {
				const user = response.data.user
				const token = response.data.token
				if (user.type === 'B2bUser') {
					//is B2B
					cookieHandler.setToken(token.id)
					commit('saveInfo', { ...user, token: token.id })
				} else {
					// is D2C
          window.location.href = `${DEFAULT_HOST_D2C}/login?token=${token.id}`;
				}
			}
      if (payload.callback) payload.callback(result)
		})
	},
	async logout({ commit }, payload) {
		return api.users.logout().then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok') {
				cookieHandler.deleteToken()
				commit('resetInfo')
			}
			if (payload.callback) payload.callback(result)
		})
	},
	async getUserInfo({ commit }, payload) {
		return api.users.getUserInfo().then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok') commit('updateInfo', response.data)
			if (payload.callback) payload.callback(result)
		})
	},
	async updateUserInfo({ commit }, payload) {
		return api.users.updateUserInfo(payload).then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok') commit('updateInfo', payload)
			if (payload.callback) payload.callback(result)
		})
	},
	async updateUserEmail({ commit }, payload) {
		return api.users.updateUserEmail(payload).then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok') commit('updateEmail', payload)
			if (payload.callback) payload.callback(result)
		})
  },
	async updateUserPassword(_, payload) {
		return api.users.updateUserPassword(payload).then((response) => {
			var result = checkResponse(response)
			if (payload.callback) payload.callback(result)
		})
  },
	async getCards({ commit, dispatch }, payload) {
		return api.users.getCards().then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok'){
        commit('updateCards', response.data)
        if(response.data.length > 0)
          dispatch('getCardAddress',response.data[0].id)
      }
			if (payload && payload.callback) payload.callback(result)
		})
  },
	async getCardAddress({ commit }, payload) {
		return api.users.getCardAddress(payload).then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok'){
        commit('updateCardAddress', response.data)
      }
			if (payload && payload.callback) payload.callback(result)
		})
  },
	async updateUserCard({ commit }, payload) {
		return api.users.updateUserCard(payload).then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok') commit('updateCard', response.data)
			if (payload.callback) payload.callback(result)
		})
  },
	async getAddresses({ commit }, payload) {
		return api.users.getAddresses().then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok') commit('updateAddresses', response.data)
			if (payload && payload.callback) payload.callback(result)
		})
  },
	async createAddress({ commit }, payload) {
		return api.users.createAddress(payload).then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok') commit('addAddress', response.data)
			if (payload && payload.callback) payload.callback(result)
		})
  },
	async editAddress({ commit }, payload) {
		return api.users.editAddress(payload).then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok') commit('editAddress', response.data)
			if (payload && payload.callback) payload.callback(result)
		})
  },
	async removeAddress({ commit }, payload) {
		return api.users.deleteAddress(payload).then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok') commit('removeAddress', payload.id)
			if (payload && payload.callback) payload.callback(result)
		})
  },
	async getRecurrables({ commit }, payload) {
		return api.users.getRecurrables().then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok') commit('updateRecurrables', response.data)
			if (payload && payload.callback) payload.callback(result)
		})
  },
	async updateRecurrable({ commit }, payload) {
		return api.users.editRecurrable(payload).then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok') commit('updateRecurrable', response.data)
			if (payload && payload.callback) payload.callback(result)
		})
  },
  loadReceipts({commit, getters}){
    if(getters.receipts.length == 0)
		return api.users.getReceipts({page: 1 }).then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok'){
        commit('updateReceiptsInitial', response.data)
      }
		})
  },
  loadReceiptsPaged({commit,getters}){
		return api.users.getReceipts({page: getters.receiptPage + 1 }).then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok'){
        commit('updateReceipts', response.data)
      }
		})
  },
	async pauseRecurrable({ commit }, payload) {
		return api.users.pauseRecurrable(payload).then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok') commit('updateRecurrableState', {id: payload.id, state: 'paused', current_order: {}})
			if (payload && payload.callback) payload.callback(result)
		})
  },
	async unpauseRecurrable({ commit }, payload) {
		return api.users.unpauseRecurrable(payload).then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok'){
        var order = response.data.orders.find(order => order.current_state === 'created')
        commit('updateRecurrableState', {id: payload.id, state: 'active', current_order: order})
      }
			if (payload && payload.callback) payload.callback(result)
		})
  },
	async updateOrder({ dispatch }, payload) {
		return api.users.updateOrder(payload).then((response) => {
			var result = checkResponse(response)
			if (result.status === 'ok') dispatch('getRecurrables')
			if (payload && payload.callback) payload.callback(result)
		})
  },
	async checkSchedule(_, payload) {
		return api.users.checkSchedule(payload).then((response) => {
			return checkResponse(response)
		})
  },
	async createUser(_, payload) {
		return api.users.createUser(payload).then((response) => {
			var result = checkResponse(response)
			//if (result.status === 'ok') dispatch('getRecurrables')
			if (payload && payload.callback) payload.callback(result)
		})
  },
	async createRecurrable(_, payload) {
		return api.users.createRecurrable(payload).then((response) => {
			var result = checkResponse(response)
			if (payload && payload.callback) payload.callback(result)
		})
  },
  csMode({commit}){
    commit('setCsModeOn')
    setTimeout(function () {
      commit('setCsModeOff')
    }, 300000)
  }
}
