export default {
	saveInfo(state, payload) {
		state.id = payload.id
		state.account_number = payload.account_number
		state.email = payload.email
		state.phone_nr = payload.phone_number
    state.opt_in = payload.opt_in
		state.first_name = payload.first_name
		state.last_name = payload.last_name
		state.company_name = payload.company_name
		state.balance = payload.balance
    state.pricePerKg = payload.price_per_kilogram
    state.pricePolicy = payload.price_policy
		state.token = payload.token
		state.timeOfLastPull = Date.now()
	},
	resetInfo(state) {
		state.id = undefined
		state.account_number = undefined
		state.email = undefined
		state.phone_nr = undefined
		state.first_name = undefined
		state.last_name = undefined
		state.company_name = undefined
		state.balance = undefined
    state.pricePerKg = undefined
    state.pricePolicy = undefined
		state.token = undefined
    state.opt_in = false
		state.timeOfLastPull = undefined
    state.receiptPage = 0
    state.receiptPageMax = 1
    state.addresses = []
    state.cards = []
    state.recurrables = []
    state.receipts = []
	},
	updateInfo(state, payload) {
		state.account_number = payload.account_number ? payload.account_number : state.account_number
		state.balance = payload.balance ? payload.balance : state.balance
		state.company_name = payload.company_name ? payload.company_name : state.company_name
		state.email = payload.email ? payload.email : state.email
		state.first_name = payload.first_name ? payload.first_name : state.first_name
		state.last_name = payload.last_name ? payload.last_name : state.last_name
		state.id = payload.id ? payload.id : state.id
		state.phone_nr = payload.phone_number ? payload.phone_number : state.phone_nr
		state.opt_in = (payload.opt_in !== undefined || payload.opt_in !== null) ? payload.opt_in : state.opt_in
  },
  updateEmail(state,payload){
    state.email = payload.email
  },
  updateCards(state,payload){
    state.cards = payload
  },
  updateCard(state,payload){
    state.cards = [payload]
  },
  updateCardAddress(state,payload){
    state.cards[0] = { ...state.cards[0], billing_address :payload }
  },
  updateAddresses(state,payload){
    state.addresses = payload
  },
  addAddress(state,payload){
    state.addresses.push(payload)
  },
  editAddress(state,payload){
    //NW
    const i = state.addresses.map(item => item.id).indexOf(payload.id)
   state.addresses[i] = payload
  },
  removeAddress(state,payload){
    const i = state.addresses.map(item => item.id).indexOf(payload)
    state.addresses.splice(i, 1);
  },
  updateRecurrables(state,payload){
    state.recurrables = payload
  },
  updateRecurrable(state,payload){
    //NW
    const i = state.recurrables.map(item => item.id).indexOf(payload.id)
    state.recurrables[i] = payload
  },
  updateRecurrableState(state,payload){
    const i = state.recurrables.map(item => item.id).indexOf(payload.id)
    state.recurrables[i] = { ...state.recurrables[i], current_state: payload.state, current_order: payload.current_order}
  },
  updateReceiptsInitial(state,payload){
    state.receipts = payload.receipts
    state.receiptPage = payload.current_page
    state.receiptPageMax = payload.total_pages
  },
  updateReceipts(state,payload){
    //todo improve
    state.receipts = state.receipts.concat(payload.receipts);
    state.receiptPage = payload.current_page
    state.receiptPageMax = payload.total_pages
  },
  setPageHistoryMax(state,payload){
    state.receiptPageMax = payload
  },
  setCsModeOn(state){
    state.cs_mode = true
  },
  setCsModeOff(state){
    state.cs_mode = false
  },
}
