import { noAuthReq, noAuthReqGet } from './generalRequest'

export default {
	async resetPassword(payload) {
		return noAuthReq('/password-reset', 'post',payload)
	},
	async setNewPassword(payload) {
		return noAuthReq('/password-reset', 'patch', payload)
	},
	subscribeNewsletter(payload) {
		return noAuthReq('/newsletters/subscribe', 'post', payload)
	},
	checkDeliveryDate(payload) {
		return noAuthReqGet('/calendar/b2b/next_shipping_date', 'get', {date: payload.date})
	},
	executeCommand(payload) {
		return noAuthReq('/commands', 'post', {payload: payload.payload})
	},
  userSources: function() {
		return noAuthReqGet('/user_sources/b2b', 'get', {})
  },
}

