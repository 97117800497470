import { noAuthReqGet } from './generalRequest'

export default {
	async getAllProducts() {
		return noAuthReqGet('/products', 'get', {})
	},
	async getCoffeePlans() {
		return noAuthReqGet('/coffee_plans/b2b', 'get', {})
	},
}


