import mutations from './user-mutations'
import actions from './user-actions'
import state from './initialState'
import cookieHandler from '../../../../components/utils/cookies/cookieHandler'
const ttp = process.env.TIME_TO_UPDATE ? process.env.TIME_TO_UPDATE : 1000

export const user = {
	namespaced: true,
	state,
	getters: {
		isOnline() {
			return cookieHandler.hasToken()
		},
		user_id(state) {
			return state.id
		},
		account_number(state) {
			return state.account_number
		},
		account_balance(state) {
			return state.balance
		},
		first_name(state) {
			return state.first_name
		},
		last_name(state) {
			return state.last_name
		},
		opt_in(state) {
			return state.opt_in
		},
		email(state) {
			return state.email
		},
		company_name(state) {
			return state.company_name
		},
		phone_nr(state) {
			return state.phone_nr
		},
		receipts(state) {
			return state.receipts
		},
    receiptPage(state){
			return state.receiptPage
    },
    receiptPageMax(state){
			return state.receiptPageMax
    },
		cards(state) {
			return state.cards
		},
		card(state) {
			return state.cards[0]
		},
		addresses(state) {
			return state.addresses
		},
		getPricePerKg: (state) => {
			return state.pricePerKg
		},
		getPricePolicy: (state) => {
			return state.pricePolicy
		},
		getAddressById: (state) => (id) => {
			return state.addresses.find((address) => address.id === id)
		},
		plans(state) {
			return state.recurrables
		},
		getPlanById: (state) => (id) => {
			return state.recurrables.find((plan) => plan.id === id)
		},
		getPlansByAddress: (state) => addressId => {
			var returnPlans = state.recurrables.filter((plan) => plan.address_id === addressId)
			return returnPlans
		},
		getPlanByOrder: (state) => (id) => {
			return state.recurrables.find((plan) => plan.current_order.id === id)
		},
		orders(state) {
			var orders = []
			state.recurrables.forEach((recurrable) => {
				if(recurrable.current_state === 'active') {
          if(Object.keys(recurrable.current_order).length !== 0 && recurrable.current_order.constructor === Object)
            orders.push(recurrable.current_order)
				}
			})
			return orders
		},
		getOrderById: (_,getters) => (id) => {
			return getters.orders.find((order) => order.id === id)
		},
		getOrdersByDate: (_,getters) => {
			var datesDict = {}
			getters.orders.forEach((order) => {
				if (datesDict[order.dispatch_on]) datesDict[order.dispatch_on].push(order)
				else datesDict[order.dispatch_on] = [order]
			})
			return datesDict
		},
		getOrdersByDateAndAddress: (_,getters) => (addressId) => {
			var datesDict = {}
			getters.orders.forEach((order) => {
        if(getters.getPlanByOrder(order.id).address_id === addressId){
          if (datesDict[order.dispatch_on]) datesDict[order.dispatch_on].push(order)
          else datesDict[order.dispatch_on] = [order]
        }
			})
			return datesDict
		},
    cs_mode(state){
      return state.cs_mode ? state.cs_mode : false
    }
	},
	mutations,
	actions,
}
