import api from '../../../api'
import { checkResponse } from '../../error'
export const shared = {
	namespaced: true,
	actions: {
		async resetPassword(_, payload) {
			return api.shared.resetPassword(payload).then((response) => {
				var result = checkResponse(response)
				if (payload.callback) payload.callback(result)
			})
		},
		async setNewPassword(_, payload) {
			return api.shared.setNewPassword(payload).then((response) => {
				var result = checkResponse(response)
				if (payload.callback) payload.callback(result)
			})
		},
		async subscribeNewsletter(_, payload) {
			return api.shared.subscribeNewsletter(payload).then((response) => {
				var result = checkResponse(response)
				if (payload.callback) payload.callback(result)
			})
		},
		async checkDeliveryDate(_, payload) {
			return api.shared.checkDeliveryDate(payload).then((response) => {
				var result = checkResponse(response)
				if (payload.callback) payload.callback(result)
			})
		},
		async quickAction(_, payload) {
			return api.shared.executeCommand(payload).then((response) => {
				var result = checkResponse(response)
				if (payload.callback) payload.callback(result)
			})
		},
		async userSources(_, payload) {
			return api.shared.userSources().then((response) => {
				var result = checkResponse(response)
				if (payload.callback) payload.callback(result)
			})
		},
	},
}
