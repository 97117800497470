import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import { user } from './modules/user'
import { shared } from './modules/shared'
import { notifications } from './modules/notifications'
import { shop } from './modules/shop'

import { signup } from './modules/signup'

//encryption in Local Storage
// https://github.com/softvar/secure-ls
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

const store = createStore({
  modules: {
    user,
    shared,
    notifications,
    signup,
    shop
  },
  plugins: [createPersistedState({
      /*rehydrated: function(store){
        console.log('water + fire',store)
      },*/
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    })],
});
/*
function haha(mutation,state){
  console.log('x')
  console.log(mutation,state)
}
store.subscribe(haha)
DEBUG */
export default store;
